





















import {Vue, Component} from 'vue-property-decorator';
import TRDA_DTO from "@/dto/request/trda/TRDA_DTO";
import Application from "@/state/Application";
import TRDAService from "@/services/request/TRDAService";
import {processError} from "@/utils/ComponentUtils";
import {prettyEnum} from "../../../utils/StringUtils";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";

@Component({
  components: {SingleFileHolder},
  methods: {prettyEnum}
})
export default class TRDA extends Vue {

  trda: TRDA_DTO | null = null;

  mounted(): void {
    this.loadTRDA();
  }

  loadTRDA(): void {
    Application.startLoading();
    TRDAService.getById(this.id).then(
        ok => {
          this.trda = ok.data;
          Application.stopLoading();
        },
        err => {
          processError(err, this);
        }
    )
  }

  get id(): number {
    return Number.parseInt(this.$route.params.id);
  }

}
